@import '../../styles/customMediaQueries.css';

.root {
  /* Layout */
  display: flex;
  flex-direction: row;
  gap: 12px;

  /* Remove link's hover effect */
  &:hover {
    text-decoration: none;
  }
}

.aspectRatioWrapper {
  transition: var(--transitionStyleButton);
  background: var(--colorGrey100); /* Loading BG color */
  border-radius: var(--borderRadiusMedium);

  @media (--viewportMedium) {
    &:hover {
      transform: scale(1.02);
      box-shadow: var(--boxShadowListingCard);
    }
  }
  & img{
    width: 180px;
  }
}

.rootForImage {
  border: solid 1px var(--colorGrey100);
  border-radius: var(--borderRadiusMedium);
  width: 100%;
  height: 100%;
  border-radius: 16px;
  background: #FFF;
}

.info {
  /* Layout */
  display: flex;
  /*flex-direction: column;*/
  padding: 0px 0 2px 0;
  flex-grow: 3;
}

.price {
  /* Layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-shrink: 0;
  margin-bottom: 4px;
}

.priceValue {
  /* Font */
  composes: textSmall from global;
  font-weight: var(--fontWeightSemiBold);

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.perUnit {
  /* Font */
  composes: textSmall from global;
  font-weight: var(--fontWeightSemiBold);

  color: var(--marketplaceColor);
  display: inline-flex;
  align-items: center;
  margin-left: 2px;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.mainInfo {
  display: flex;
  flex-direction: column;
  min-height: 130px;
  justify-content: space-between;
}

.title {
  /* Font */
  composes: textSmall from global;
  font-weight: 500;
  color: #000;
  margin: 0;
  font-size: 26px;
  margin-bottom: 10px;
  line-height: 28px;
}

.authorInfo {
  /* Font */
  composes: marketplaceTinyFontStyles from global;
  font-weight: var(--fontWeightMedium);
  color: var(--colorGrey700);

  padding-top: 4px;
  padding-bottom: 4px;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

.listingBlock{
  display: flex;
  gap: 28px;
  flex-direction: column;
  @media (--viewportMedium) {
    flex-direction: row;
  }

  & img{}
}
.listingDescription{
font-size: 14px;
color: #000;
}
.listingImg{
  width: 100%;
  @media (--viewportMedium) {
    width: 190px;
    min-width: 190px;
  }
}
.listingAddress{
  padding: 2px 10px;
  background: #d6d6d6;
  color: #000;
  font-weight: 600;
  border-radius: 4px;
}
.listingRatings{
  padding: 2px 10px;
  background: #ead9c8;
  color: #000;
  font-weight: 600;
  border-radius: 4px;
}

.listingBottomSection{
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 11px;
}
.priceSection{
  display: flex;
  gap: 10px; 
  flex-direction: column;

  & .checkPrice{
background: #FFF;
color: #e46d2b;
padding: 10px 10px;
min-width: 110px;
min-height: 66px;
  }

  & .checkOffer{
    background: #d6d6d6;
    color: #000;
    padding: 10px 10px;
    min-width: 110px;
    min-height: 66px;
  }
}